@import 'appVaribles';
@import 'appCommonStyle';
@import 'appIcons';
/*----------main header -----------------*/
// .drop{
//   height: 120px!important;
//   line-height: 2;
// }

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.32);
  .top-nav {
    // height: $top-nav-height;
    background-color: $app-primary-color;
    position: relative;
    z-index: 2;
    .navbar {
      padding: 0px;
      background-color: transparent;
      justify-content: space-between;
    }
    .navbar-brand {
      padding: 0px 0;
      position: relative;
      z-index: 1;
    }
    .app-title {
      padding: 26px 0;
      font-family: $font-boldItalic;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      text-align: center;
      .navbar-brand {
        padding: 8px 0;
        position: relative;
        z-index: 1;
      }
      .tough-title {
        color: #fff;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 0px;
        letter-spacing: 0.9px;
      }
      .made-title {
        color: $app-secondary-color;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
      }
    }
    // .right-box {
    //   position: relative;
    //   z-index: 1;
    //   padding: 32px 0;
    //   @extend %display-flex;
    // }
    // .user-login {
    //   .nav-link {
    //     padding: 0 16px;
    //     @extend %display-flex;
    //     align-items: center;
    //     &:hover {
    //       i {
    //         color: lighten($app-secondary-color, 25%);
    //       }
    //     }
    //   }
    //   .user-name {
    //     font-family: $font-bold;
    //     font-size: 14px;
    //     color: rgb(255, 254, 245);
    //     padding-right: 36px;
    //     display: inline-block;
    //     vertical-align: middle;
    //     letter-spacing: 0.3px;
    //   }
    //   i {
    //     display: inline-block;
    //     vertical-align: middle;
    //     font-size: 30px;
    //     color: $app-secondary-color;
    //   }
    // }
    // .add-cart {
    //   padding: 0 16px;
    //   font-size: 30px;
    //   color: $app-secondary-color;
    //   display: inline-block;
    //   vertical-align: middle;
    //   line-height: normal;
    //   position: relative;
    //   span {
    //     font-family: $font-bold;
    //     font-size: 16px;
    //     position: absolute;
    //     top: 9px;
    //     left: 0px;
    //     right: 0;
    //     margin: auto;
    //     text-align: center;
    //   }
    //   &:hover {
    //     i,
    //     span {
    //       color: lighten($app-secondary-color, 25%);
    //     }
    //   }
    // }
    // .custom-dropdown {
    //   background-color: black;
    // }
    // .menu-toggle {
    //   display: none;
    //   padding: 3px 16px;
    //   border: none;
    //   outline: none;
    //   @extend %common-transition;
    //   svg {
    //     stroke: $app-secondary-color;
    //   }
    //   &:hover {
    //     svg {
    //       stroke: lighten($app-primary-color, 20%);
    //     }
    //   }
    // }
  }
  .right-box {
    position: relative;
    z-index: 1;
    padding: 32px 0;
    @extend %display-flex;
  }

  .user-login {
    position: absolute;
    right: 70px !important;
    top: -75px;
    z-index: 3;
    @extend %display-flex;

    .nav-link {
      padding: 0 16px;
      @extend %display-flex;
      align-items: center;
      &:hover {
        i {
          color: lighten($app-secondary-color, 25%);
        }
      }
    }
    .user-name {
      font-family: $font-bold;
      font-size: 14px;
      color: rgb(255, 254, 245);
      padding-right: 40px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 0.3px;
    }
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      color: $app-secondary-color;
    }
  }
  .add-cart {
    padding: 0 16px;
    font-size: 30px;
    color: $app-secondary-color;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    position: relative;
    span {
      font-family: $font-bold;
      font-size: 16px;
      position: absolute;
      top: 9px;
      left: 0px;
      right: 0;
      margin: auto;
      text-align: center;
    }
    &:hover {
      i,
      span {
        color: lighten($app-secondary-color, 25%);
      }
    }
  }
  .right-box-navbar {
    .cart-icon-login-navbar {
      display: none;
    }
  }
  .custom-dropdown {
    background-color: black;
  }
  .menu-toggle {
    display: none;
    padding: 3px 0px;
    border: none;
    outline: none;
    @extend %common-transition;
    svg {
      stroke: $app-secondary-color;
      width: 52px;
      height: 71px;
      margin-top: -18px !important;
      right: 6px !important;
    }
    &:hover {
      svg {
        stroke: lighten($app-secondary-color, 20%);
      }
    }
  }
  .navv {
    display: block;
    height: $links-nav-height;

    background-color: $app-secondary-color;
    background-image: url(../images//nav-stripe-bg.svg);
    background-repeat: no-repeat;
    background-position: top right;
    @extend %common-transition;
    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;
      i {
        color: $app-primary-color;
      }
    }
  }
  // .pop-over{
  //   .action-button {
  //     display: inline-block;

  .pop {
    .action-button {
      display: inline-block;

      height: 30px;
      width: 30px;
      background: #d3d3d3;
      color: #000;
      font-size: 16px;
      line-height: 30px;
      border-radius: 3px;
      @extend %common-transition;
      margin-right: 10px;
      display: none;
      padding: 15px 20px;

      position: fixed !important;

      // position: absolute;

      // right: 14px;
      // top: 14px;
      // font-size: 30px;
      // i {
      //   color: $app-primary-color;
      // }
      &:hover {
        background-color: #000;
        color: #fff;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .app-links-nav {
    display: block;
    height: $links-nav-height;
    background-color: $app-secondary-color;
    background-image: url(../images//nav-stripe-bg.svg);
    background-repeat: no-repeat;

    background-position: top right;
    @extend %common-transition;
    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;
      i {
        color: $app-primary-color;
      }
    }
    .okapi {
      // width: 200px;
      width: 60px;
    }
    .nav-item {
      position: relative;
    }
    .nav-link {
      position: relative;
      line-height: 50px;
      padding: 0 11px;
      font-family: $font-boldItalic;
      font-size: 12px;
      color: $app-primary-color;
      text-transform: uppercase;
      @include css3-prefix(transition, color 100ms ease-in-out);
      &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: 50%;
        width: 54px;
        height: 16px;
        margin-left: -20px;
        background: url(../images/nav-linkActive-arrow.svg) no-repeat 0px -5px;
        opacity: 0;
      }
      &:hover:not(.active),
      &:focus:not(.active) {
        color: #000;
      }
      &.active {
        &:before {
          opacity: 1;
        }
      }
    }
    .header-search {
      padding: 8px 0 8px 12px !important;
    }
    .header-search-box {
      position: relative;
      margin-bottom: 0px;
      input {
        height: 32px;
        border-radius: 6px;
        min-width: 300px;
        background-color: rgba(29, 85, 50, 0.2);
        font-size: 14px;
        color: #000;
        padding-right: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
        @include css3-prefix(transition, all 100ms ease-in-out);
        &:focus {
          border-color: $app-primary-color;
          & + i {
            color: #000;
          }
        }
      }
      ::-webkit-input-placeholder {
        color: $app-primary-color;
      }
      ::-moz-placeholder {
        color: $app-primary-color;
      }
      :-ms-input-placeholder {
        color: $app-primary-color;
      }
      :-moz-placeholder {
        color: $app-primary-color;
      }
      i {
        position: absolute;
        right: 8px;
        top: 7px;
        color: $app-primary-color;
      }
    }
  }

  &.login-header {
    .top-nav .app-title {
      padding-top: 28px;
    }
    .app-links-nav {
      display: none;
    }
  }
}
.nav-container {
  position: relative;
}
/*----------main footer -----------------*/

.main-footer {
  height: $footer-height;
  background-color: $app-primary-color;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .cust-container {
    @extend %display-flex;
    justify-content: space-between;
  }
  .center-box {
    font-family: $font-light;
    font-size: 12px;
    color: #fff;
    padding: 7px 0;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 0px;
    margin: 0 auto;
    max-width: 350px;
    a.logo {
      display: inline-block;
      vertical-align: middle;
      width: 53px;
      margin: 0 9px;
    }
  }
  .footer-link {
    line-height: 15px;
    margin-right: 22px;
    line-height: 50px;
    white-space: nowrap;
    .terms {
      font-family: $font-light;
      font-size: 12px;
      line-height: 50px;
      color: #fff;
      text-decoration: underline;
      padding: 0 3px;
      top: -2px;
      position: relative;
      @extend %common-transition;
      &::before {
        content: '';
        position: absolute;
        height: 13px;
        width: 1px;
        right: -5px;
        top: 16px;
        background-color: #fff;
      }
      &:hover {
        color: $app-secondary-color;
        text-decoration: underline;
      }
      // &:hover {
      //   span {
      //     color: $app-secondary-color;
      //   }
      // }
      // &:last-child {
      //   margin-right: 0px;
      // }
    }
    i,
    span {
      @extend %common-transition;
    }
    i {
      font-size: 12px;
      color: $app-secondary-color;
    }
    span {
      color: #fff;
      font-family: $font-light;
      font-size: 12px;
      padding-left: 12px;
      line-height: normal;
    }
    &:hover {
      span {
        color: $app-secondary-color;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  nav {
    ul {
      margin: 0px;
    }
    li {
      p {
        font-family: $font-light;
        font-size: 12px;
        color: #fff;
        text-decoration: underline;
        padding: 0 3px;
        position: relative;
        @extend %common-transition;
        &::before {
          content: '';
          position: absolute;
          height: 13px;
          width: 1px;
          right: -5px;
          top: 2px;
          background-color: #fff;
        }
        &:hover {
          color: $app-secondary-color;
          text-decoration: underline;
        }
      }
      &:last-child {
        p {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
.login-footer {
  .main-footer {
    height: 60px;
    .center-box {
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      margin: auto;
    }
    .right-box {
      margin-top: 1rem;
    }
  }
}
/*----------main container --------------*/
.main-wrapper {
  position: relative;
}
main {
  padding-top: $top-nav-height + $links-nav-height;
  min-height: 100vh;
  padding-bottom: $footer-height;
  &.login-main-page {
    padding-bottom: $footer-height !important;
    padding-top: 100px;
  }
}

/*----------login section----------------*/
.login-section {
  @include calc(min-height, '100vh - #{$top-nav-height} - #{$footer-height}');
  @include BannerBackground('../images/login-bg', 'jpg');
  @extend %display-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .login-box {
    position: relative;
    width: 631px;
    height: 600px;
    margin-top: 80px;
    border-radius: 0px;
    border: none;
    background-image: url('../images/login-box-bg.svg');
    background-repeat: no-repeat;
    background-position: 100.1% 100.5%;
    .bottom-links {
      position: absolute;
      bottom: 26px;
      left: 0px;
      right: 0px;
      margin: auto;
      font-family: $font-light;
      font-size: 14px;
      color: #000;
      text-decoration: underline;
      text-align: center;
      a {
        display: inline-block;
        height: 25px;
        line-height: 25px;
        text-transform: capitalize;
        font-family: 'HelveticaNeue-Bold';
        font-size: 14px;
        border-radius: 5px;
        padding: 0 15px;
        text-align: center;
        background-color: #1d5532;
        color: #fff;
        border: none;
        text-decoration: none;
        @extend %common-transition;
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
    .login-form {
      max-width: 318px;
      padding: 0 15px;
      margin: 0 auto;
    }
    .btn-box {
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .forget-link {
      font-family: $font-light;
      font-size: 14px;
      color: #a2a2a2;
      @extend %common-transition;
      &:hover {
        color: darken(#a2a2a2, 20%);
      }
    }
  }
}

/*--------content-page---------------------*/
.content-page {
  padding-top: 65px;
  .section-row {
    @extend %display-flex;
    margin-left: -10px;
    margin-right: -10px;
    .section-col {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .section-header {
    margin-bottom: 20px;
  }
}

/*------------home-section---------------------*/
.view {
  color: #1d5532;
  font-style: italic;
}

.with-abs-button {
  position: relative;
  button {
    position: absolute;
    right: 50px;
    top: 17px;
  }
}
.invoice-header {
  margin-top: -50px;
}
.invoice-grid-box {
  .invoice-grid-scroll-container {
    overflow-x: auto;
  }
  .invoice-table {
    width: 100%;

    thead {
      margin-top: -10px;
      th {
        background-color: $app-primary-color;
        height: 40px;
        vertical-align: middle;
        font-family: $font-bold;
        font-weight: normal;
        font-size: 14px;
        color: $app-secondary-color;
        border: none;
        padding: 5px 8px;
        &.action-col {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        height: 20px;
        &:hover {
          background-color: #f7f7f7;
        }
        td,
        th {
          border: none;
          font-family: $font-normal;
          font-size: 14px;
          color: #000;
          height: 30px;
          vertical-align: middle;
          padding: 12px 8px;
          &.action-col {
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
          }

          .react-toggle {
            vertical-align: middle;
          }
        }
      }
    }
    &.with-border {
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      th,
      td {
        border-bottom: 1px solid #000;
      }
    }
  }
}
.home-section {
  .no-promotion {
    margin-top: -130px;
  }
  .section-row {
    flex-wrap: nowrap;
    .section-col {
      &.left-col {
        max-width: 500px;
        min-width: 500px;

        // margin-top: -130px;
      }
      &.right-col {
        flex: 1;
      }
    }
  }
  .material-section {
    padding: 54px 0;
    @include BannerBackground('../images/material-section-bg', 'jpg');
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;
    h2,
    h4 {
      position: relative;
      z-index: 1;
      font-family: $font-boldItalic;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
    h2 {
      font-size: 40px;

      line-height: 50px;
    }
    h4 {
      font-size: 15px;
    }
    .material-links-row {
      @extend %display-flex;
      margin-bottom: 54px;
      .material-box {
        flex: 1;
        height: 450px;
        position: relative;
        outline: none;
        @extend %display-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          color: #fff;
        }
        h4 {
          color: $app-secondary-color;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          height: 100%;
          @include calc(width, '100% - 54px');
          background-color: $app-primary-color;
          @include css3-prefix(transform, skewX(-16deg));
        }
      }
    }
    .subscribe-box {
      height: 241px;
      position: relative;
      @extend %display-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 382px 0 66px;
      color: $app-secondary-color;
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        height: 100%;
        width: 200%;
        background-color: $app-secondary-color;
        right: 160px;
        border-radius: 0px 90px 47px 0;
        @include css3-prefix(transform, skewX(-16deg));
      }
      h2,
      h4 {
        color: $app-primary-color;
      }
      form {
        min-width: 290px;
        position: relative;
      }
    }
  }
  .newslist-wrapper {
    .news-item {
      cursor: pointer;
    }
  }
}

/*----------news list-------------------------*/
.newslist-wrapper {
  // padding-left: 64px;
  .news-item {
    margin-bottom: 35px;
    span {
      margin-bottom: 5px;
    }
    span,
    p {
      font-size: 14px;
      color: #000;
      display: block;
      line-height: 16px;
    }
    h4 {
      font-family: $font-bold;
      font-size: 20px;
      color: #000;
      line-height: 25px;
    }
  }
}
/*------------popup--------------------------*/
.confirm-popup {
  .popup-text {
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
  }
}
/*--------news-addEdit---------------------*/
.news-addEdit {
  .image-uploads {
    .form-group {
      display: inline-block;
      vertical-align: top;
      min-width: 240px;
      img {
        max-width: 100%;
      }
      label {
        display: block;
      }
      input {
        display: block;
        margin-bottom: 10px;
      }
      .thumbnail {
        width: 102px;
        min-width: 102px;
        display: inline-block;
        padding: 4px;
        margin-bottom: 0px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        &.image-large {
          width: auto;
        }
      }
    }
  }
}
/*---------------grids------------------*/
.news-grid {
  .Title-col {
    min-width: 95px;
  }
  .Content-col {
    min-width: 320px;
  }
  .Thumbnail-col {
    min-width: 200px;
  }
  .ImageUrl-col {
    min-width: 200px;
  }
}

/*--------------marketing section------------*/
.marketing-section {
}
.marketing-client-section {
  .marketing-table {
    min-width: 650px;
    .date-col {
      min-width: 100px;
    }
  }
}
.email-link {
  // margin-right: 20px;
  color: blue;
}
/*------------------training-------------*/
.traning-client-section {
  .training-grid {
    min-width: 650px;
    .date-col {
      min-width: 100px;
    }
  }
}
/*-------admin-dashboard---------*/
.section-header {
  span {
    .text {
      margin-bottom: 100px;
      position: relative;
    }
  }
  .input-box {
    margin-top: -8px;
  }
}
.admin-table {
  // width: 1000px;
  // align-items: center;
}
/*------------newsCustomer-section----------*/
.newsCustomer-section {
  .newsbox-container {
    overflow: hidden;
  }
  .news-row {
    margin-right: -112.5px;
    margin-left: -112.5px;
    .news-col {
      padding: 0 112px;
    }
  }
  .news-card {
    border: none;
    margin-bottom: 85px;
    cursor: pointer;
    .card-body {
      padding: 0px;
    }
    .card-img-top {
      margin-bottom: 19px;
    }
    .card-title {
      color: #000;
      font-family: $font-normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
    }
    .card-subtitle {
      font-family: $font-boldItalic;
      font-size: 24px;
      color: $app-primary-color;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 45px;
    }
    .card-text {
      font-size: 16px;
      color: #000;
    }
  }
}

/*------------fullNews-page-------------------*/
.fullNews-page {
  .news-banner {
    height: 486px;
    background-color: #eee;
    @include BannerBackground('../images/news-banner', 'png');
  }
  .news-container {
    max-width: 1246px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    padding-top: 44px;
  }
  .close-button {
    position: absolute;
    right: 15px;
    top: 42px;
  }
  .news-title {
    color: #000;
    font-family: $font-normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 17px;
  }

  .news-sub-title {
    font-family: $font-boldItalic;
    font-size: 24px;
    color: $app-primary-color;
    line-height: 29px;
    text-transform: uppercase;
    margin-bottom: 45px;
  }
  .news-text-container {
    p {
      font-size: 16px;
      color: #000;
      line-height: 33px;
    }
  }
}

/*------------catalogue----------------*/
.catalogue-section {
  .footer-button-box {
    padding-top: 24px;
  }
  .sub-menu {
    padding: 0px;
    li {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .accordion {
    .card-body {
      padding: 10px 0px;
    }
  }
}
// .add-icon{
//   position: relative;
// }
.cam-btn {
  color: #1d5532;
}
// .fix-width-card{
//   min-width: 340px;
// }
.catalogue-card {
  border: 1px solid #000;
  position: relative;
  border-radius: 0px;

  margin-bottom: 20px;
  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }
  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }
  .card-body {
    border-top: 1px solid #000;
  }
  .product-image {
    height: 369px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // object-fit: cover;
    // width: 369px;
  }
  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }
    @extend %display-flex;
    margin-bottom: 22px;
    .left-box,
    .right-box {
      flex: 1;
    }
    &.action-row {
      flex-wrap: wrap;
      .left-box {
        width: auto;
        min-width: 60px;
        margin-bottom: 5px;
      }
      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }
  .header-text {
    margin-bottom: 22px;
    p {
      margin-bottom: 0px;
    }
  }
  .card-title {
    font-family: $font-bold;
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
  }
  .card-text {
    font-size: 14px;
    color: #000;
    line-height: 16px;
    span {
      display: inline-block;
    }
  }
  .button-box {
    padding-top: 8px;
  }
}

/*------------cart---------------*/
// .po-placeholder{
//  padding: 600px 600px;
// }
.upload-profilephoto {
  .upload-button {
    margin-left: 300px;
  }
  .file-name {
    left: 130px !important;
    position: relative;
    display: block;
    text-align: center;
  }
  .error-file {
    margin-left: 130px;
  }
  .green-title {
    font-size: 16px;
  }
}

.cart-section {
  .grid-header-actions {
    align-items: flex-end;
    .left-box {
      max-width: 597px;
    }
  }
  .app-grid-box {
    .no-product {
      position: absolute;
      margin-top: -100px;
    }
    .app-grid {
      .code-col,
      .price-col {
        min-width: 100px;
      }
      .desc-col {
        min-width: 300px;
      }
      .action-col {
        min-width: 188px;
      }
      tfoot {
        th,
        td {
          padding: 8px 8px;
          color: #000;
          font-size: 14px;
        }
        th {
          font-family: $font-bold;
          padding-right: 15px;
        }
        .value-text {
          border: 1px solid #000;
          box-shadow: -1px 0 0 0 #000;
        }
      }
    }
  }
  h3.box-title {
    font-size: 16px;
    font-family: $font-bold;
    color: $app-primary-color;
    text-transform: uppercase;
  }
  .date-select-box {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 88px;

    .select {
      display: inline-block;
      padding: 0 10px;
      select {
        border: none;
        border-bottom: 1px solid #000;
        font-size: 14px;
        font-family: $font-light;
        color: #000;
        line-height: 38px;
        border-radius: 0px;
      }
    }
  }
  .select-payment-box {
    text-align: center;
    & > h3.box-title {
      margin-bottom: 17px;
    }
    .payment-options {
      .payment-chk {
        display: inline-block;
        padding: 0 17px;
        label {
          display: inline-block;
          position: relative;
          cursor: pointer;
          margin-bottom: 0px;
          &:hover .chk-style,
          input:checked ~ .chk-style {
            border-color: lighten($app-primary-color, 20%);
            background-color: lighten($app-primary-color, 20%);
            h3,
            i {
              color: #ffff;
            }
          }
          .chk-style,
          h3,
          i {
            @extend %common-transition;
          }
          .chk-style {
            min-width: 260px;
            border: 1px solid $app-primary-color;
            border-radius: 10px;
            padding: 16px;
            color: $app-primary-color;
            position: relative;
            padding: 9px 0 2px;
          }
          input {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }
          h3.box-title {
            margin-bottom: 12px;
          }
          i {
            font-size: 85px;
            line-height: 65px;
          }
        }
      }
    }
  }
  .footer-button-box {
    padding-top: 82px;
    padding-bottom: 85px;
  }
}

/*-------------loader----------------------*/
.sweet-loading {
  .css-198xogd {
    border-color: $app-primary-color $app-primary-color transparent;
  }
}

.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: '\f067';
}

.bootstrap-demo .toggle.expanded::after {
  content: '\f068';
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}
/*--------------customerAccReg-section---------------------*/
.stepForm-container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}
.customerAccReg-section,
.standupForm-section {
  overflow: hidden;
  .form-step {
    h4.green-title,
    p {
      font-size: 18px;
      color: $app-primary-color;
    }
    h2,
    h4,
    strong,
    h6 {
      font-family: $font-bold;
    }
    h2 {
      font-size: 34px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      padding-top: 40px;
    }
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-position: right 16px center;
    }
    .text-content {
      margin-bottom: 20px;
    }
    .step-common-section {
      // padding: 20px 0;
      // border-bottom: 1px solid #f6f6f6;
      padding: 20px 15px 5px;
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.11);
      margin: 17px 0;
    }
    .contact-input {
      // input:first-child {
      //   max-width: 68px;
      // }
    }
    .same-inputs-group {
      background: #eee;
      padding: 8px 8px 0;
      margin-bottom: 10px;
      .form-group {
        margin-bottom: 10px;
      }
    }
    .cell-bot-align {
      @extend %display-flex;
      align-items: flex-end;
      .form-group {
        flex: 1;
      }
    }
    .same-inputs-group {
      .with-add-remove-btns {
        padding-right: 0;
        padding-top: 40px;
        .add-remove-btn {
          position: absolute;
          top: 0;
          right: -8px;
        }
      }
    }
  }
  .step-button-box {
    padding-bottom: 20px;
    @extend %display-flex;
    .right-box {
      margin-left: auto;
    }
    button {
      &.step-btn {
        font-size: 20px;
        padding: 0 30px;
        height: 54px;
      }
    }
  }
  .step1 {
    padding: 60px 0px;
    h4 {
      margin-bottom: 40px;
    }
    h3.green-title,
    ol.documents-list {
      color: #1d5532;
    }
    ol.documents-list {
      padding-left: 26px;
      li {
        margin-bottom: 3px;
      }
    }

    .step-button-box {
      button {
        margin: 0 10px;
        text-transform: uppercase;
      }
    }
  }
  .step2 {
    .sub-header {
      margin-bottom: 25px;
      @extend %display-flex;
      justify-content: space-between;
      .reg-info {
        border: 1px solid #000;
        text-align: center;
        padding: 19px 50px;
        h5,
        p {
          margin-bottom: 0px;
          color: #000;
        }
        h5 {
          font-family: $font-bold;
          font-size: 18px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .step3 {
    .Account-Secured-inputs {
      .cust-radio-group {
        margin-bottom: 5px;
      }
    }
    .title-trade {
      margin-top: 20px;
    }
  }
  .step4 {
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }
    .documents-list {
      list-style-type: decimal;
      padding-left: 28px;
      li {
        font-size: 16px;
        margin-bottom: 8px;
        color: #353535;
        padding-left: 8px;
      }
    }
    .uploaded-documents-list {
      margin-bottom: 10px;
      li {
        position: relative;
        font-size: 18px;
        color: #353535;
        padding: 9px 47px 9px 14px;
        &:hover {
          background-color: #f4f4f4;
        }
        button {
          position: absolute;
          right: 0px;
          border: none;
          top: 0;
          height: 100%;
        }
      }
    }
    .upload-button {
      display: block;
      margin: 0 auto;
    }
  }
  .step5 {
    h4 {
      color: #000;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  .step6 {
    .step-header {
      position: relative;
      padding: 0 180px;
      margin-bottom: 45px;
      .left-box {
        position: absolute;
        left: 0px;
        bottom: 20px;
      }
      .center-box {
        max-width: 690px;
        text-align: center;
        margin: 0 auto;
        img {
          width: 100px;
          margin-bottom: 10px;
        }
        h3 {
          font-size: 22px;
        }
      }
    }
    .list-title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .turms-list {
      padding-left: 25px;
      margin-bottom: 30px;
      li {
        display: block;
        position: relative;
        padding-left: 40px;
        line-height: 22px;
        margin-bottom: 12px;
        &:before {
          position: absolute;
          left: 0;
          font-size: 14px;
        }
        ul {
          padding-top: 10px;
          padding-left: 0px;
          li {
            padding-left: 50px;
          }
        }
      }
      @for $i from 1 through 30 {
        &.turms#{$i} {
          counter-reset: turmsItem;
          & > li {
            &:before {
              content: '#{$i}.'counters(turmsItem, '.');
              counter-increment: turmsItem;
            }
          }
        }
      }
    }
    .accept-checkbox {
      text-align: center;
      margin-bottom: 28px;
    }
  }
  .step7 {
    h2 {
      font-size: 53px;
      border-bottom: 0;
      color: $app-primary-color;
    }
    p.step1-text {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
}
/*----------userAccount-section-------------------------------*/

.useraccount-form {
  .inp {
    outline: 0;
    border-width: 0 0 1px;
    border-color: 1px solid #000000;
    padding-left: 0px;
  }
}
.useraccount-button {
  .save-send {
    height: 40px;
    text-align: center;
    background-color: $app-primary-color;
    color: #fff;
    line-height: 40px;
    margin-top: 75.5px;
    width: 390px;
    height: 40px;
    margin-left: 415px;
    padding: 0 10px;
    font-family: $font-bold;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    border: none;
    &:hover,
    &:focus {
      background-color: #000;
      box-shadow: none;
      color: #fff;
    }
    &.full-width {
      width: 100%;
      min-width: auto;
    }
  }
}

/*----------userAccount-section-------------------------------*/
.account-header {
  margin-top: 3.5%;
}
.useraddAccount-section {
  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }
  .user-table-data {
    .account-action {
      text-align: right;
      padding-right: 36px;
      min-width: 150px;
    }
    th.account-action {
      button {
        margin-left: 11px;
      }
    }
  }
}

/*-----------------userAddAccount-page---------------------*/
.account-header {
  margin-top: 3.5%;
}
.useraccount-section {
  .step-common-section {
    box-shadow: none;
    .form-group {
      input {
        border: none;
        border-bottom: 1px solid #000;
        padding-left: 0px;
        font-size: 14px;
      }
      ::-webkit-input-placeholder {
        /* Edge */
        color: #000;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000;
      }

      ::placeholder {
        color: #000;
      }
    }
  }
  // .with-sidebar .sidebar-content {
  //   max-width: 714px;
  // }
  .content-page {
    padding-right: 318px;
  }
  .btn-box {
    padding-top: 60px;
    button {
      width: 100%;
    }
  }
}

/*--------------Switch-Account-section---------------------*/
.switch-account-header {
  margin: 3.5%;
  // .section-header{
  // .btn{
  // //   margin-left:59%;
  // //   min-width: 100px;
  // position: relative;
  // bottom: 13px;
  // //  margin-bottom: 5px;

  // }
  // }
  h2.with-abs-button {
    position: relative;
    button {
      position: absolute;
      right: 50px;
      top: 12px;
    }
  }
}

.switch-account-table-box {
  font-size: 14px;
  .switch-account-table {
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }
    .accountName-col {
      padding-right: 140px;
    }
    .switch-col {
      padding-right: 0px;
    }
  }
}

/*--------------Open-Orders-section---------------------*/
.openorder-header {
  margin-top: 3.5%;
}
.openorders-table-box {
  // border-color: black !important;

  table {
    border-color: black !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }
  // .table th, .table td {
  //     padding: 0.75rem;
  //     vertical-align: top;
  //     // border-top: 1px solid black;
  //     width: 500px;
  // }
  .openorders-table-data {
    min-width: 650px;
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
      font-size: 14px;
    }
    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }

    .order-col {
      padding-left: 60px;
    }
  }
}

/*----------back-order-------------------------*/
.backorder-header {
  margin-top: 3.5%;
  .out-of-stock-header {
    margin-top: 4%;
  }
}
// Invoices
.invoice-table-box {
  // border-color: black !important;
  table {
    border-color: black !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }
}

/*--------------Stock-Sheets-section---------------------*/

.stocksheet-section {
  .act-col {
    padding-right: 10px;
  }
  .stock-header {
    margin-top: 3.5%;
  }
  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }
  .stocksheet-table-box {
    .stocksheet-table-data {
      min-width: 640px;
      .table-bordered th,
      .table-bordered td {
        border: 1px solid black;
      }
      thead {
        color: $app-secondary-color;
        background-color: $app-primary-color;
        font-size: 14px;
      }

      .action-col {
        padding-right: 70px;
      }
      .act-button {
        margin-left: 8px;
        top: 432px;
        width: 30px;
        height: 30px;
        background: #d3d3d3 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
        display: inline-block;
      }

      tbody {
        font-size: 14px;
        color: #000;
        border-radius: 6px 2px 2px 2px;
        .stock-button {
          margin-right: -15%;
        }
        .stock-action {
          margin-right: 0;
        }
        th.stock-action {
          padding-right: 0px;
          button {
            top: 497px;
            left: 1469px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
.act-col {
  padding-left: 50px;
}

/*-----------myaccount-section-------------------*/
.myaccount-header {
  margin-top: 3%;
  .myaccount-text {
    font-size: 20px;
  }
}
.myaccount-table-box {
  // border-color: black;
  .myaccount-table {
    font-size: 14px;
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .current-col {
      padding-left: 60px;
    }
    .days-col {
      margin-right: 20px;
    }
  }
}
.myact-table {
  border-color: black;
}

/*-------------stocksheetscreate----------------------*/

.stocksheets-create-header {
  .stocksheet-table {
    margin-top: 7.3%;
  }
  margin-top: 3.5%;
}

.stocksheets-create-placeholder {
  margin-top: 20%;
  .align-save-placeholder {
    padding-left: 45px;
    .file-icon {
      color: #1d5532;
      position: absolute;
      left: 12px;
      margin-top: 12px;
    }
    top: 255px;
    left: 1397px;
    height: 14px;
    opacity: 1;

    input {
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 22px;
      font-size: 14px;
      display: inline;
      width: 65%;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }
  .align-save-placeholder {
    display: inline;

    .save {
      top: 235px;
      color: white;
      left: 1675px;
      width: 99px;
      height: 40px;
      background: #1d5532 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      display: inline;
      float: inline-end;
    }
  }
}
.stockcreate-table-box {
  margin-top: 55px;

  .stockcreate-table-data {
    max-width: 400px;
    width: 94%;

    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
      font-size: 14px;
    }
    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

/*----------standupform-section-------------------------------*/
.standupform-header {
  margin-top: -5%;
}

/*--------------order-section------------------*/
.order-printer {
  right: 0;
}
.order-value-text {
  font-size: 14px;
}
.order-section {
  tfoot {
    th,
    td {
      padding: 8px 8px;
      color: #000;
      font-size: 14px;
    }
    th {
      font-family: $font-bold;
      padding-right: 15px;
    }
    .order-value-text {
      border: 1px solid #000;
      box-shadow: -1px 0 0 0 #000;
    }
  }
  .order-header {
    .order-text {
      font-size: 14px;
    }
    .order-middle {
      font-size: 30px;
      color: $app-primary-color;
      font-family: Helvetica Neue;
    }
    .order-right-text {
      font-size: 14px;
    }
  }
}

/*-----------rep client contact history-------------------*/
.rep-client-history-header {
  margin: 3.5%;
  .contact-message {
    z-index: 1;
    color: black;
  }
  .message-textarea {
    background: #1d553233 0% 0% no-repeat padding-box;
  }
  .rep-client-history-save {
    margin-top: 2.5%;
    top: 500px;
    left: 1468px;
    width: 99px;
    height: 40px;
    background: var(--unnamed-color-1d5532) 0% 0% no-repeat padding-box;
    background: #1d5532 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    float: right;
    color: white;
  }
}

.rep-client-history-table-box {
  margin-top: 5.5%;
  font-size: 14px;
  .rep-client-history-table {
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }
    .accountName-col {
      padding-right: 120px;
    }
    .message-col {
      padding-right: 190px;
    }
    .view-col {
      padding-right: 20px;
    }
  }
}
.date-filter {
  text-align: center;
  display: flex;
  flex-direction: column;
}
/*----------report-section-------------------------------*/
.report-section {
  .report-header {
    margin-top: 3.5%;
    .from-date-input {
      margin-left: 22%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }
    .to-date-input {
      margin-left: 60%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }
    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }
  .customer-report {
    margin-left: 10%;
    margin-top: 15%;
    .rep-customer-report {
      display: inline;
      margin-left: 10%;
    }
    .revenue-report {
      display: inline;
      margin-left: 10%;
    }
    .orders-report {
      display: inline;
      margin-left: 10%;
    }
  }
}

/*-----------print products-------------------*/

.print-product-card {
  border: 1px solid #000;
  margin-top: 3%;
  position: relative;
  border-radius: 0px;
  margin-bottom: 20px;
  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }
  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }
  .card-body {
    border-top: 1px solid #000;
  }
  .print-product-image {
    height: 213px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
  }
  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }
    @extend %display-flex;
    margin-bottom: 22px;
    .left-box,
    .right-box {
      flex: 1;
    }
    &.action-row {
      flex-wrap: wrap;
      .left-box {
        width: auto;
        min-width: 60px;
        margin-bottom: 5px;
      }
      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }
}

page[size='A4'] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  .card-body {
    border-top: 1px solid #000;
  }
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}
page[size='A4'][layout='landscape'] {
  width: 29.7cm;
  height: 21cm;
}

/*----------standformagreement-section-------------------------------*/
.r-text {
  color: #000;
}
.agreement-header {
  margin-top: -5%;
}
.green-title-agreement {
  color: $app-primary-color;
}
.black-title-agreement {
  color: #000;
}
.standform-agreement {
  color: #000;
  font-size: 14px;
  .r-text {
    color: #000;
    font-size: 14px;
    .submit-formagreement {
      button {
        float: right;
      }
    }
  }
  .agreement-input {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #000;
    padding-left: 0px;
    font-size: 14px;
  }
}
.sigPad {
  width: 100%;
  height: 300px;
}

.AccountNavActive {
  font-size: 25px;
}
/*----------------product-details-view----------------*/
.product-details-view {
  display: flex;
  flex-wrap: wrap;
  .product-image-wrapper,
  .product-details {
    width: 50%;
  }
  .product-image-wrapper {
    .product-image {
      height: 590px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border: 1px solid #000;
    }
  }
  .product-details {
    padding-left: 40px;
    h3.productname {
      font-size: 30px;
      line-height: 36px;
      color: #000;
      margin-bottom: 36px;
    }
    .product-summary-details {
      margin: 0px;
      .col {
        padding: 0px;
      }

      .detail-wrapper {
        margin-bottom: 40px;
        h5,
        p {
          line-height: normal;
          font-size: 14px;
        }
        h5 {
          font-family: $font-bold;
          margin-bottom: 3px;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
    .product-quantity {
      margin-bottom: 60px;
    }
  }
  .product-description {
    margin-bottom: 50px;
    p {
      font-size: 14px;
      color: #000;
      line-height: 16px;
      margin-bottom: 0px;
    }
  }
  .product-more-detail {
    padding-top: 58px;
  }
  h4.products-title {
    font-size: 16px;
    font-family: $font-boldItalic;
    color: $app-primary-color;
    margin-bottom: 16px;
  }
  .no-font {
    font-size: 14px;
  }
}
/*------------*/
.useraccount-section.useraccountadd-section
  .access-control-section
  .form-group {
  margin-bottom: 22px;
}
.useraccount-section.useraccountadd-section
  .access-control-section
  .app-chk
  .chk-text {
  font-size: 20px;
}
.useraccount-section.useraccountadd-section
  .access-control-section
  .clearfix
  .app-chk
  .chk-text {
  font-size: 16px;
  color: #161616;
}
.useraccount-section.useraccountadd-section .btn-box {
  padding-top: 22px;
  padding-bottom: 20px;
  button {
    width: 150px;
    margin-right: 15px;
  }
}
.css-1pahdxg-control {
  border-color: #1d5532 !important;
  box-shadow: 0 0 0 1px #1d5532 !important;
}

.requiredClass {
  font-weight: 900;
  color: red;
}
.pop-over {
  text-transform: uppercase;
}
.popover {
  max-width: 600px;
  padding: 20px 20px;
  background-color: #1d5532;
  left: 8% !important;
  border-radius: 0rem !important;
}
.popover-body {
  color: white;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  text-align: left;
}

.popover .arrow {
  border-top-color: #1d5532;
  left: 10% !important;
  width: 100px;
  color: #1d5532 0% 0% no-repeat padding-box;
  position: fixed;
}
.arrow::after {
  color: #1d5532;
}
.popover .tooltip {
  color: #1d5532;
}
.remove-style {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: #1d5532;
}

.remove-style:hover {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: black;
}

.change-pointer {
  cursor: pointer;
}

.change-pointer:hover {
  cursor: pointer;
  color: #ffdd00;
}

//  Add category button
.add-categorybtn {
  font-size: 11px;
}
.add-subscribersbtn {
  font-size: 11px;
}
// Modal in home.js

.modal-dialog {
  width: 100%;
  // margin: 0rem!important;
}
// .main-header.login-header .top-nav .navbar {
//   padding: 15px 0px !important;
// }
.nav-Image-width.row {
  padding: 10px 0;
}
/*-----------responsive-------------------*/
@import 'responsive.scss';

// product print
main.pb-0 {
  .printProduct-section {
    .main-product-print-footer {
      position: unset !important;
    }
  }
  .login-footer .main-footer .center-box {
    position: unset !important;
    margin: 0 !important;
  }
}

.app-grid-box .app-grid.with-border th.status-col {
  width: 200px !important;
  @media all and (max-width: 767px) {
    width: unset !important;
  }
}
.red-color {
  background-color: red;
}
.pdf-container {
  width: 100%;
  height: 800px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-search {
  width: 53%;
}

.header-search-box-1 {
  position: relative;
  input {
    height: 50px;
    border-radius: 0;
    min-width: 600px;
    background-color: #fff;
    font-size: 16px;
    color: #2f5633;
    padding: 14px 240px 15px 16px;
    outline: none;
    box-shadow: none;
    border: 2px solid #b2b2b2;
    transition: all 0.1s ease-in-out;
  }
  ::placeholder {
    color: #2f5633 !important;
    opacity: 1;
  }
  i {
    position: absolute;
    right: 0;
    top: 2px;
    padding: 16px 0 0 18px;
    color: #2f5633;
    font-size: 17px;
    background: #fbdd33;
    height: 46px;
    width: 50px;
    cursor: pointer;
    font-weight: bolder;
  }
}

.search-toggle {
  background-color: #d3d3d3;
  position: absolute;
  // top: 2px;
  width: 175px;
  height: 50px;
  right: 49px;
  border-left: 2px solid #b2b2b2;
  font-size: 14px;
  text-align: center;
  search-option {
    height: 40px;
  }
}
:focus {
  outline: none;
}

.react-autosuggest__suggestions-container {
  background-color: #d3d3d3;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 50px;
  width: 100%;
  .container {
    justify-content: start;
  }
}
.new-dropdown .dropdown-item {
  background: #f0f0f0 !important;
}

.new-dropdown:before {
  content: '';
  position: absolute;
  top: 0;
  right: -3%;
  width: 54px;
  height: 16px;
  margin-left: -31px;
  // background: url(/static/media/nav-linkActive-arrow-red.ef294fad.svg) no-repeat 0 -5px;
  opacity: 1;
}
