$app-primary-color: #1d5532;
$app-secondary-color: #ffdd00;

$top-nav-height: 100px;
$links-nav-height: 50px;
$footer-height: 60px;

$font-normal: 'HelveticaNeue';
$font-bold: 'HelveticaNeue-Bold';
$font-boldItalic: 'HelveticaNeue-BoldItalic';
$font-light: 'HelveticaNeue-Light';

/*placeholders*/
%display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

%flex-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

%common-transition {
  -webkit-transition: all 100ms ease-in-out;
  -khtml-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
/*css mixin*/
@mixin flex($flex-value, $width-value: null) {
  -webkit-box-flex: $flex-value;
  -moz-box-flex: $flex-value;
  -webkit-flex: $flex-value;
  -ms-flex: $flex-value;
  flex: $flex-value;
  @if ($width-value !=null) {
    width: $width-value;
  }
}

@mixin BannerBackground($image-path, $image-type) {
  background: url($image-path + '.' + $image-type) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin WebTransation($anim-time, $anim-type) {
  -webkit-transition: all $anim-time $anim-type;
  -moz-transition: all $anim-time $anim-type;
  -ms-transition: all $anim-time $anim-type;
  -o-transition: all $anim-time $anim-type;
  transition: all $anim-time $anim-type;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}
